import React from 'react';

import Box from '../Box'
import { Wrapper } from "./ACommonStyles";
import { getPcsUrls } from "./AHelpers";

import { styled } from "../../../stitches.config";


export default props => {
	const blockSettings = props.settings.settings;
	const pcs = getPcsUrls(blockSettings?.pcs_background_mobile, blockSettings?.pcs_background_desktop);
	const hasPcs = (blockSettings?.pcs_background_mobile?.length > 0 || blockSettings?.pcs_background_desktop?.length > 0);

	return (
		<Root>
			<section className="container" data-tb-region={props.blockSettings.tb_region}>
				<Wrapper className="row" pcs={pcs}>
					<div className="col-xs-12 col-md-6">
						<Box {...props} id="b1" type="BTextOnPicture" settings={props.settings.b1} classes="" />
					</div>
					<div className="col-xs-12 col-md-3">
						<Box {...props} id="b2" type="BPhotoOnTop" settings={props.settings.b2} style={hasPcs || props.blockGroupHasBackground ? 'with-padding' : null} classes="" />
					</div>
					<div className="col-xs-12 col-md-3">
						<Box {...props} id="b3" type="BPhotoOnTop" settings={props.settings.b3} style={hasPcs || props.blockGroupHasBackground ? 'with-padding' : null} classes="" />
					</div>
				</Wrapper>
			</section>
		</Root>
	);
}

const Root = styled("div", {
	"@bp4": {
		marginTop: "40px",
	},
});
